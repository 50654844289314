import React, { useState } from 'react'
import {Row, Col, Container} from "react-bootstrap"
import Layout from "../components/layout"
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import "../styles/index.scss"

function SportsModal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="icon"><img src="/icons/sportIcon.svg"/></div>
          Sports
        </Modal.Title>
        <h4>Choose your location</h4><br/>
        <Button href="https://sports.chelseapiers.com/new-york/chelsea/welcome">Chelsea, NY</Button><br/><br/>
        <Button href="https://sports.chelseapiers.com/connecticut/stamford/welcome">Stamford, CT</Button>
      </Modal.Body>
    </Modal>
  );
}

function EventsModal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="icon"><img src="/icons/eventIcon.svg"/></div>
          Events
        </Modal.Title>
        <h4>Choose your location</h4><br/>
        <Button href="https://events.chelseapiers.com/new-york/chelsea/welcome">Chelsea, NY</Button><br/><br/>
        <Button href="https://events.chelseapiers.com/connecticut/stamford/welcome">Stamford, CT</Button>
      </Modal.Body>
    </Modal>
  );
}

function IndexPage() {
  const [modalShowSports, setModalSportsShow] = React.useState(false);
  const [modalShowEvents, setModalEventsShow] = React.useState(false);
  return (
  <Layout>
    <Container className="header">
      <Row>
        <Col lg="6" className="logo-wrapper">
          <img className="logo" src="/logos/ChelseaPiers_Row.svg" />
        </Col>
        <Col lg="6">
          <div className="header-copy">
          Chelsea Piers in Manhattan, Brooklyn and Connecticut offers unrivaled spaces, activities, and communities with a passion for inspired fitness, sports, wellness and events.
          </div>
        </Col>
      </Row>
    </Container>
    <Container fluid className="main-content">
      <Row className="g-0">
        <Col lg="4" >
          <a onClick={() => setModalSportsShow(true)}>
            <div className="item sports">
                <div className="icon"><img src="/icons/sportIcon.svg"/></div>
                <div className="title">Sports</div>
            </div>
          </a>
        </Col>
        <Col lg="4">
          <a href="https://fitness.chelseapiers.com">
            <div className="item fitness">
                <div className="icon"><img src="/icons/fitnessIcon.svg"/></div>
                <div className="title">Fitness</div>
            </div>
          </a>
        </Col>
        <Col lg="4">
          <a onClick={() => setModalEventsShow(true)}>
            <div className="item events">
                <div className="icon"><img src="/icons/eventIcon.svg"/></div>
                <div className="title">Events</div>
            </div>
          </a>
        </Col>
      </Row>
    </Container>
    <Container className="footer">
      <Row>
        <Col lg={{ span: 6, offset: 3 }} className="copyright text-center">
          © {(new Date().getFullYear())} Chelsea Piers. All Rights Reserved.&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;   
          <a href="https://www.chelseapiers.com/company/online-privacy-policy/" target="_blank">PRIVACY POLICY</a>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;   
          <a href="https://www.chelseapiers.com/company/accessibility-statement/" target="_blank">ACCESSIBILITY STATEMENT</a>
        </Col>
        {/* <Col lg="6" className="social">
          <div className="wrapper">
            <a href="https://www.instagram.com/chelseapiersfitness/" target="_blank"><img className="icon" src="/icons/insta.svg" /></a>
            <a href="https://www.facebook.com/chelseapiersfitness" target="_blank"><img className="icon" src="/icons/facebook.svg" /></a>
            <a href="https://twitter.com/chelseapiersfit" target="_blank"><img className="icon" src="/icons/twitter.svg" /></a>
            <a href="https://www.linkedin.com/company/chelsea-piers-fitness/" target="_blank"><img className="icon" src="/icons/linkedin.svg" /></a>
            <a href="https://www.youtube.com/channel/UC0P9y7nOsSGEn2Zo_kX_Oag" target="_blank"><img className="icon" src="/icons/youtube.svg" /></a>
          </div>
        </Col> */}
      </Row>
    </Container>
    <SportsModal
        show={modalShowSports}
        onHide={() => setModalSportsShow(false)}
      />
      <EventsModal
        show={modalShowEvents}
        onHide={() => setModalEventsShow(false)}
      />
  </Layout>
  )
}

export default IndexPage
